let colors = {
    'hausastrid': '#A82D03',
    'suedstrand': '#3F7BA1',
    'hausnina': '#4688BE'
};

export const state = () => ({
    fewoDatas: {

        'hausnina': [
            {
                "logotitle": "Ferienwohnungen Haus Nina",
                "logo": "2020-01-29-logo-hausnina.svg",
                "color": colors.hausnina,
                "headline": "Ferienwohnungen für 1-6 Personen im Herzen von Warnemünde",
                "description": "Ferienwohnungen für 1-6 Personen im Herzen von Warnemünde",
                "headerimgMobile": "hausnina/buehne_HH_768x768@2x.jpg",
                "headerimg": "hausnina/buehne_HN@2x.jpg",
                "qm": "49 m²",
                "price": "95,00 €",
                "footer": '<p>Besuchen Sie auch unsere Ferienwohnungen am <a href="https://www.suedstrand-wohnung.de/" target="_blank"><span class="icon-house"></span>Südstrand</a> in Fehmarn oder im <a href="https://www.hausastrid.de/" target="_blank"><span class="icon-house"></span>Haus Astrid</a> in Rostock/Warnemünde</p>',
                "fewos": [
                    {
                        "gallery": [
                            {
                                "caption": "Wohnzimmer",
                                "img": 'hausnina/gallery/strandperle/strandperle-wohnzimmer@2x.jpg',
                                "thumb": require('~/assets/img/hausnina/gallery/strandperle/strandperle-wohnzimmer@2x.jpg?resize').src,
                                "src": require('~/assets/img/hausnina/gallery/strandperle/strandperle-wohnzimmer@2x.jpg?resize').src
                            },
                            {
                                "caption": "Wohnzimmer",
                                "img": 'hausnina/gallery/strandperle/strandperle-wohnzimmer2@2x.jpg',
                                "thumb": require('~/assets/img/hausnina/gallery/strandperle/strandperle-wohnzimmer2@2x.jpg?resize').src,
                                "src": require('~/assets/img/hausnina/gallery/strandperle/strandperle-wohnzimmer2@2x.jpg?resize').src
                            },
                            {
                                "caption": "Schlafzimmer",
                                "img": 'hausnina/gallery/strandperle/strandperle-schlafzimmer@2x.jpg',
                                "thumb": require('~/assets/img/hausnina/gallery/strandperle/strandperle-schlafzimmer@2x.jpg?resize').src,
                                "src": require('~/assets/img/hausnina/gallery/strandperle/strandperle-schlafzimmer@2x.jpg?resize').src
                            },
                            {
                                "caption": "Schlafzimmer",
                                "img": 'hausnina/gallery/strandperle/strandperle-schlafzimmer2@2x.jpg',
                                "thumb": require('~/assets/img/hausnina/gallery/strandperle/strandperle-schlafzimmer2@2x.jpg?resize').src,
                                "src": require('~/assets/img/hausnina/gallery/strandperle/strandperle-schlafzimmer2@2x.jpg?resize').src
                            },
                            {
                                "caption": "Küche",
                                "img": 'hausnina/gallery/strandperle/strandperle-kueche@2x.jpg',
                                "thumb": require('~/assets/img/hausnina/gallery/strandperle/strandperle-kueche@2x.jpg?resize').src,
                                "src": require('~/assets/img/hausnina/gallery/strandperle/strandperle-kueche@2x.jpg?resize').src
                            },
                            {
                                "caption": "Bad",
                                "img": 'hausnina/gallery/strandperle/strandperle-bad@2x.jpg',
                                "thumb": require('~/assets/img/hausnina/gallery/strandperle/strandperle-bad@2x.jpg?resize').src,
                                "src": require('~/assets/img/hausnina/gallery/strandperle/strandperle-bad@2x.jpg?resize').src
                            },
                            {
                                "caption": "Terasse",
                                "img": 'hausnina/gallery/strandperle/Terasse-unten@2x.jpg',
                                "thumb": require('~/assets/img/hausnina/gallery/strandperle/Terasse-unten@2x.jpg?resize').src,
                                "src": require('~/assets/img/hausnina/gallery/strandperle/Terasse-unten@2x.jpg?resize').src
                            },
                            {
                                "caption": "Grundriss",
                                "img": 'hausnina/gallery/strandperle/grundriss_Strandperle@2x.jpg',
                                "thumb": require('~/assets/img/hausnina/gallery/strandperle/grundriss_Strandperle@2x.jpg?resize').src,
                                "src": require('~/assets/img/hausnina/gallery/strandperle/grundriss_Strandperle@2x.jpg?resize').src
                            }
                        ],
                        "title": "Strandperle",
                        "description": "<p>Die 3-Zimmer Wohnung liegt im Erdgeschoss und eignet sich für 1-6 Personen. Sie besteht aus einem Wohnzimmer mit einer gemütlichen Terrasse, die genügend Platz und Sitzgelegenheiten für ein leckeres Essen oder einen Plausch im Freien bietet." +
                            "<br/>Die zwei separaten Schlafzimmer verfügen jeweils über ein Doppelbett. Eine weitere Ausziehcouch für 2 Personen befindet sich im Wohnzimmer.</p>" +
                            "<p>Weiterhin gibt es eine komplett ausgestattete Küche und zwei Badezimmer mit Dusche.</p>" +
                            "<p>Ein Babybett und ein Hochstuhl können bei Bedarf hinzugestellt werden.</p>" +
                            "<p><strong>Ausstattung:</strong><br/>" +
                            "Kabelfernsehen, Radio, Geschirrspüler, Dusche/WC, Tiere erlaubt, WLan vorhanden, Bettwäsche und Handtücher auf Anfrage.</p>",
                        "price": "95,00 €",
                        "qm": "85 m²",
                        "person": "max. 6",
                        "priceNight": "95,00 - 115,00 €",
                        "dog": "10,00 €",
                        "cleaning": "65,00 €"
                    },
                    {
                        "gallery": [
                            {
                                "caption": "Wohnzimmer",
                                "img": 'hausnina/gallery/strandburg/Strandburg_wohnzummer@2x.jpg',
                                "thumb": require('~/assets/img/hausnina/gallery/strandburg/Strandburg_wohnzummer@2x.jpg?resize').src,
                                "src": require('~/assets/img/hausnina/gallery/strandburg/Strandburg_wohnzummer@2x.jpg?resize').src
                            },
                            {
                                "caption": "Schlafzimmer",
                                "img": 'hausnina/gallery/strandburg/Strandburg_schlafzimmer1@2x.jpg',
                                "thumb": require('~/assets/img/hausnina/gallery/strandburg/Strandburg_schlafzimmer1@2x.jpg?resize').src,
                                "src": require('~/assets/img/hausnina/gallery/strandburg/Strandburg_schlafzimmer1@2x.jpg?resize').src
                            },
                            {
                                "caption": "Schlafzimmer 2",
                                "img": 'hausnina/gallery/strandburg/Strandburg_schlafzimmer2@2x.jpg',
                                "thumb": require('~/assets/img/hausnina/gallery/strandburg/Strandburg_schlafzimmer2@2x.jpg?resize').src,
                                "src": require('~/assets/img/hausnina/gallery/strandburg/Strandburg_schlafzimmer2@2x.jpg?resize').src
                            },
                            {
                                "caption": "Küche",
                                "img": 'hausnina/gallery/strandburg/Strandburg_kueche@2x.jpg',
                                "thumb": require('~/assets/img/hausnina/gallery/strandburg/Strandburg_kueche@2x.jpg?resize').src,
                                "src": require('~/assets/img/hausnina/gallery/strandburg/Strandburg_kueche@2x.jpg?resize').src
                            },
                            {
                                "caption": "Bad",
                                "img": 'hausnina/gallery/strandburg/Strandburg_bad@2x.jpg',
                                "thumb": require('~/assets/img/hausnina/gallery/strandburg/Strandburg_bad@2x.jpg?resize').src,
                                "src": require('~/assets/img/hausnina/gallery/strandburg/Strandburg_bad@2x.jpg?resize').src
                            },
                            {
                                "caption": "Balkon",
                                "img": 'hausnina/gallery/strandburg/Strandburg_balkon1@2x.jpg',
                                "thumb": require('~/assets/img/hausnina/gallery/strandburg/Strandburg_balkon1@2x.jpg?resize').src,
                                "src": require('~/assets/img/hausnina/gallery/strandburg/Strandburg_balkon1@2x.jpg?resize').src
                            },
                            {
                                "caption": "Balkon",
                                "img": 'hausnina/gallery/strandburg/Strandburg_balkon2@2x.jpg',
                                "thumb": require('~/assets/img/hausnina/gallery/strandburg/Strandburg_balkon2@2x.jpg?resize').src,
                                "src": require('~/assets/img/hausnina/gallery/strandburg/Strandburg_balkon2@2x.jpg?resize').src
                            }
                        ],
                        "title": "Strandburg",
                        "description": "<p>Diese geräumige 3-Zimmer Wohnung im 1. Stock von Haus Nina eignet sich für 1-5 Personen. Vom komfortabel eingerichteten Wohnzimmer gelangen Sie auf den großen, sonnenüberfluteten Balkon, der zum ausgiebigen Relaxen einlädt und viel Platz für ein gemütliches Essen im Freien bietet.</p>" +
                            "<p>Die beiden separaten Schlafzimmer sind unterschiedlich ausgestattet. Eines verfügt über ein Doppelbett, das andere über ein Doppelbett und ein weiteres Bett.</p>" +
                            "<p>Eine komplett eingerichtete Küche und ein Bad mit Dusche sind ebenfalls vorhanden.</p>" +
                            "<p>Ein Babybett und ein Hochstuhl können bei Bedarf hinzugestellt werden.</p>" +
                            "<p><strong>Ausstattung:</strong><br/>" +
                            "Kabelfernsehen, Radio, Geschirrspüler, Dusche/WC, Tiere erlaubt, WLan vorhanden, Bettwäsche und Handtücher auf Anfrage.</p>",
                        "price": "95,00 €",
                        "qm": "81 m²",
                        "person": "max. 5",
                        "priceNight": "95,00 - 115,00 €",
                        "dog": "10,00 €",
                        "cleaning": "65,00 €"
                    }
                ],
                "seasons": [],
                "priceinfo": "<p>Die genannten Preise beziehen sich auf eine Übernachtung pro Ferienwohnung. Im Mietpreis enthalten sind Strom und Wasser. Bettwäsche kann nach Absprache gestellt werden. Bitte berücksichtigen Sie, dass unsere Preise je nach Anzahl der Gäste und Saisonzeit variieren können. Ein verbindliches Angebot für den von Ihnen gewünschten Zeitraum erhalten Sie daher nur auf Anfrage.<\/p>",
                "locationinfo": "Haus Astrid befindet sich ruhig und zentral im Herzen von Warnemünde. Der Strand ist etwa 250 m entfernt, das Zentrum und den Alten Strom erreichen Sie in 5 Gehminuten. Auch der Leuchtturm und die Mole befinden sich in unmittelbarer Nähe.",
                "address": "<p>Anastasiastraße 3<br/>18119 Rostock (Warnemünde)<br/>Deutschland</p>",
                "mapslink": "https://www.google.de/maps/dir//Anastasiastra%C3%9Fe+3,+18119+Rostock/@54.1774979,12.0144243,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x47ac561fe4d129a7:0xcd9cebabe9b6af97!2m2!1d12.0846352!2d54.1775183",
                "maps": "hausnina/map-haus-nina@2x.jpg",
                "contactAddress": "<p>Heinz-Georg Schäfer<br/>\n" +
                    "                        Edelhofdamm 6<br/>\n" +
                    "                        13465 Berlin<br/>\n<br/>\nTel: + 49 (0) 30 401 63 84 <br/>\n" +
                    "                        Fax: + 49 (0) 30 401 02 113<br/>\n<br/>\n</p>",
                "contactEMail": "kontakt@hausnina.de",
                "contactHeadline": "Buchen Sie Ihre Ferienwohnung im Haus Nina",
                "contactInfos": [
                    {
                        "icon": "icon-private.svg",
                        "title": "Privater Anbieter"
                    },
                    {
                        "icon": "icon-raum-zu-freien-entfaltung.svg",
                        "title": "Ideal für Familien"
                    },
                    {
                        "icon": "icon-keine-versteckten-Kosten.svg",
                        "title": "Keine versteckten Kosten"
                    },
                    {
                        "icon": "icon-seebadarchitektur.svg",
                        "title": "Traditionelle Seebadarchitektur"
                    }
                ]
            }
        ]
    }
})
